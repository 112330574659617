import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError } from "../../../components/Toasts/toasts";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all boardmember
export const viewBoardmembers = createAsyncThunk(
  "boardmember",
  async (thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;

    try {
      let response = await axios.get(`${server}/boardmembers/publication-all`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

//List all boardmember
export const singleBoardmembers = createAsyncThunk(
  "boardmember",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      let response = await axios.get(`${server}/boardmembers/dashboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error(response.data.message);
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        toast.error(e.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
