import React, { useState, useEffect } from "react";
//import Slider from "./Slider";
//import ProgramArea from "./ProgrammArea";
import MissionVission from "./MissionVission";
import Partners from "./Partners";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn } from "../animations/animation";
import { MdDateRange, MdLocationOn } from "react-icons/md";
import CountUp from "react-countup";
import { MdPlace, MdAttachMoney, MdPeople, MdBusiness } from "react-icons/md";
import axios from "axios";

import { Autoplay, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/controller";
import slider1 from "../../assets/gallery/gallery1.jpg";
import slider2 from "../../assets/gallery/gallery2.jpg";
import slider3 from "../../assets/gallery/gallery3.jpg";
import slider4 from "../../assets/gallery/gallery4.jpg";
import slider5 from "../../assets/gallery/gallery5.jpg";
import slider6 from "../../assets/gallery/gallery6.jpg";
import slider7 from "../../assets/gallery/gallery7.jpg";
import slider8 from "../../assets/gallery/gallery8.jpg";
import {
  GiVillage,
  GiWorld,
  GiThreeFriends,
  GiFirstAidKit,
  GiShield,
  GiOpenBook,
} from "react-icons/gi";
import { toast } from "react-toastify";

import UNHCR from "../../assets/partnersanddonors/1.HelpAge.png";
import USAID from "../../assets/partnersanddonors/2. IRC.png";
import IGAD from "../../assets/partnersanddonors/3. ESAP.png";
import OCHA from "../../assets/partnersanddonors/4. Action Aid.png";
import IOM from "../../assets/partnersanddonors/5. KNH.png";
import IRC from "../../assets/partnersanddonors/6. CDP.jpg";
import CARE from "../../assets/partnersanddonors/7. Imagine1day.jpg";
import WV from "../../assets/partnersanddonors/8. BMZ.png";
import GLRA from "../../assets/partnersanddonors/9. PCI.png";
import WFP from "../../assets/partnersanddonors/10. IIRR.png";
import FRC from "../../assets/partnersanddonors/11. HoRAEC.jpg";
import Coopi from "../../assets/partnersanddonors/12. Oxfam GB.png";
import COOP from "../../assets/partnersanddonors/13. EU civil society fund.png";

import MFM from "../../assets/partnersanddonors/14. MFM.png";
import CSSP2 from "../../assets/partnersanddonors/15. CSSP2.png";
import AJWS from "../../assets/partnersanddonors/16. American Jewish World Service.png";
import CCRDA from "../../assets/partnersanddonors/17. CCRDA.png";
import OXFM from "../../assets/partnersanddonors/18. Oxfam Intermon.png";
import OXFMA from "../../assets/partnersanddonors/20. Oxfam America.png";
import TU from "../../assets/partnersanddonors/21. Trickle Up.png";
import HC from "../../assets/partnersanddonors/22. Help a child.png";
import NZ from "../../assets/partnersanddonors/23. Netherland embassy.png";
import NE from "../../assets/partnersanddonors/24. Norwegian Embassy.png";
import FE from "../../assets/partnersanddonors/25. French Embassy.png";
import Marquee from "react-fast-marquee";

import Profile1 from "../../assets/boardmembers/image1.jpg";
import Profile2 from "../../assets/boardmembers/image2.jpg";
import Profile4 from "../../assets/boardmembers/image4.jpg";
import defaultImage from "../../assets/images/profile.png";
import { backend_url } from "../../server";
import { useDispatch, useSelector } from "react-redux";
import { viewContent } from "../../States/Action/Content-Action/ContentAction";
import { viewBoardmembers } from "../../States/Action/Boardmember-Action/BoardmemberAction";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { contents } = useSelector((state) => state.contents);
  const newsItems = contents || [];

  const { boardmember } = useSelector((state) => state.boardmember);
  const newBoardmember = boardmember || [];
  useEffect(() => {
    dispatch(viewBoardmembers());
  }, []);

  // console.log("view all bordmembers :", boardmember);

  useEffect(() => {
    dispatch(viewContent({ currentPage, searchTerm }));
  }, [dispatch, currentPage, searchTerm]);

  const programmaticAreas = [
    {
      id: 1,
      title: "Sustainable Livelihood and Resilience",
      icon: <GiVillage size={50} />,
    },
    {
      id: 2,
      title: "Education and Skill Development",
      icon: <GiOpenBook size={50} />,
    },
    {
      id: 3,
      title: "Emergency and Humanitarian Support",
      icon: <GiFirstAidKit size={50} />,
    },
    {
      id: 4,
      title: "Women, Child and Older Person Empowerment",
      icon: <GiThreeFriends size={50} />,
    },
    {
      id: 5,
      title: "Climate Change Response and Peace Building",
      icon: <GiWorld size={50} />,
    },
    { id: 6, title: "Citizens Right Protection", icon: <GiShield size={50} /> },
  ];

  const partners = [
    { name: "UNHCR", image: UNHCR },
    { name: "USAID", image: USAID },
    { name: "IGAD", image: IGAD },
    { name: "OCHA", image: OCHA },
    { name: "IOM", image: IOM },
    { name: "IRC", image: IRC },
    { name: "CARE", image: CARE },
    { name: "WV", image: WV },
    { name: "GLRA", image: GLRA },
    { name: "WFP", image: WFP },
    { name: "FRC", image: FRC },
    { name: "Coopi", image: Coopi },
    { name: "COOP", image: COOP },
    { name: "UNHCR", image: MFM },
    { name: "USAID", image: CSSP2 },
    { name: "IGAD", image: AJWS },
    { name: "OCHA", image: CCRDA },
    { name: "IOM", image: OXFM },
    { name: "IRC", image: OXFMA },
    { name: "CARE", image: TU },
    { name: "WV", image: HC },
    { name: "GLRA", image: NZ },
    { name: "WFP", image: NE },
    { name: "FRC", image: FE },
  ];

  const RecentNews = () => (
    <>
      <div className="text-center mt-4">
        <h2 className="text-xl md:text-2xl border-b-2 font-bold uppercase pb-4 mb-4">
          Recent News
        </h2>
      </div>

      <div className="flex flex-wrap justify-center items-center mt-4">
        {newsItems.slice(0, 4).map((newsItem) => (
          <div key={newsItem.id} className="w-full md:w-1/2 lg:w-1/4 px-2 mb-8">
            <motion.div
              className="rounded-lg overflow-hidden flex flex-col justify-center items-center py-6"
              style={{ border: "#FFF solid" }}
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div className="relative items-center">
                <img
                  // src={Profile1}
                  src={`${backend_url}/${newsItem.media_file}`}
                  alt="news"
                  style={{ height: "400px", width: "400px" }}
                  // onClick={() => handleCardClick(newsItem)}
                />
              </div>
              <div className="mt-3 items-center">
                <h3 className="mb-1 text-xl font-bold text-blue-700">
                  {newsItem.title}
                </h3>
                <p className="mb-1 text-lg font-semibold font-blue-700">
                  {newsItem.description.substring(0, 100)}
                  <span>...</span>
                </p>
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdDateRange />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {newsItem.createdAt}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdLocationOn />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {newsItem.location}
                    </span>
                  </div>
                </div>
              </div>
              <Link to="/news">
                <button className="text-xl mx-2 mt-4 align-middle bg-[#87CEEB] select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3">
                  Read More
                </button>
              </Link>
            </motion.div>
          </div>
        ))}
      </div>
    </>
  );

  const BoardMembers = () => (
    <>
      <div className="text-center mt-4">
        <h2 className="text-xl md:text-2xl border-b-2 font-bold uppercase pb-4 mb-4">
          Board Members
        </h2>
      </div>

      <div className="flex flex-wrap bg-[#87CEEB] justify-center mt-8">
        {/* Loop through board members data and render member cards */}
        {boardmember?.boardmember?.length > 0
          ? boardmember?.boardmember.slice(0, 4).map((member) => (
              <div key={member.id} className="w-full md:w-1/4 p-4">
                <div
                  className="rounded-lg overflow-hidden flex flex-col justify-center items-center py-6 h-full"
                  // style={{ height: "100%" }}
                >
                  <div className="w-full h-full flex justify-center items-center overflow-hidden">
                    <img
                      src={`${backend_url}/${member.board_member_photo}`}
                      alt="Board Member"
                      className="object-cover h-full w-full" // Ensure image covers the container and maintains aspect ratio
                    />
                  </div>
                  <h3 className="text-xl font-semibold my-2">
                    {member?.board_member_name}
                  </h3>
                  <p className="text-gray-700">
                    {member?.board_member_profession}
                  </p>
                </div>
              </div>
            ))
          : null}

        <div className="w-full ml-2 flex justify-center mt-8">
          <Link to="/aboutus">
            <button className="ml-2 text-xl px-4 py-2 bg-[#87CEEB] rounded-md shadow hover:bg-blue-600 transition-colors">
              See More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
  const ProgrammaticArea = () => (
    <>
      <div className="text-center mt-4">
        <motion.div
          variants={fadeIn("down", 0.4)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
        >
          <h2 className="text-xl md:text-2xl border-b-2 font-bold uppercase pb-4 mb-4">
            Program Areas
          </h2>
        </motion.div>
      </div>

      <motion.div
        variants={fadeIn("down", 0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
      >
        <div className="flex flex-wrap mt-4">
          {programmaticAreas.map((area, index) => (
            <div
              key={index}
              className="w-full md:w-1/3 lg:w-1/3 xl:w-1/3 px-4 mb-8"
            >
              <div className="flex flex-col items-center justify-center h-full text-center">
                {area.icon}

                <h2 className="text-lg font-semibold mt-4">{area.title}</h2>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );

  const Counter = () => (
    <div className="flex flex-wrap md:justify-between items-center bg-[#87CEEB]">
      <div className="w-full md:w-1/4 flex flex-col justify-center items-center p-4 border-r border-white">
        <motion.div
          variants={fadeIn("down", 0.4)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="text-center flex flex-col items-center"
        >
          <MdPlace className="text-4xl mb-4" />
          <div className="text-4xl font-bold">
            <CountUp start={0} end={9} duration={2} decimals={0} />
          </div>
          <h5 className="uppercase mt-2">Coordination Offices</h5>
        </motion.div>
      </div>
      <div className="w-full md:w-1/4 flex flex-col justify-center items-center p-4 border-r border-white">
        <motion.div
          variants={fadeIn("down", 0.4)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="text-center flex flex-col items-center"
        >
          <MdAttachMoney className="text-4xl mb-4" />
          <div className="text-4xl font-bold">
            <CountUp start={1} end={12.5} duration={2} decimals={1} />
            <span> M+</span>
          </div>
          <h5 className="uppercase mt-2">Fund mobilized</h5>
        </motion.div>
      </div>
      <div className="w-full md:w-1/4 flex flex-col justify-center items-center p-4 border-r border-white">
        <motion.div
          variants={fadeIn("down", 0.4)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="text-center flex flex-col items-center"
        >
          <MdPeople className="text-4xl mb-4" />
          <div className="text-4xl font-bold">
            <CountUp start={1} end={2} duration={3.5} decimals={1} />
            <span> M+</span>
          </div>
          <h5 className="uppercase mt-2">Beneficiaries (direct+indirect)</h5>
        </motion.div>
      </div>
      <div className="w-full md:w-1/4 flex flex-col justify-center items-center p-4">
        <motion.div
          variants={fadeIn("down", 0.4)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: false, amount: 0.7 }}
          className="text-center flex flex-col items-center"
        >
          <MdBusiness className="text-4xl mb-4" />
          <div className="text-4xl font-bold">
            <CountUp start={0} end={30} duration={2.7} decimals={0} />
            <span className="text-2xl text-bold"> +</span>
          </div>
          <h5 className="uppercase mt-2">Partners & Donors</h5>
        </motion.div>
      </div>
    </div>
  );

  const Slider = () => (
    <div className="swiper-container h-screen ">
      <Swiper
        modules={[Autoplay, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        className="h-full w-full"
      >
        {contents && contents?.length > 0 ? (
          contents.map((img, index) => (
            <SwiperSlide key={index} className="relative h-full w-full">
              <img
                src={`${backend_url}/${img.media_file}`}
                alt={`slide-${index + 1}`}
                className="w-full h-full object-cover"
              />
              <motion.div
                variants={fadeIn("right", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.3 }}
              >
                <div className="absolute bottom-5 left-5 bg-black bg-opacity-50 text-white py-2 px-4 text-lg rounded">
                  {img.title}
                </div>
              </motion.div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className="relative h-full w-full">
            <img src={slider1} className="w-full h-full object-cover" />
            <motion.div
              variants={fadeIn("right", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.3 }}
            >
              <div className="absolute bottom-5 left-5 bg-black bg-opacity-50 text-white py-2 px-4 text-lg rounded">
                Tsegaye Koji
              </div>
            </motion.div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
  const MissionVission = () => (
    <div>
      <div className="flex flex-wrap bottommargin-lg items-stretch mt-8">
        <div className="flex flex-wrap bottommargin-lg items-stretch mt-8">
          <div
            className="lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#87CEEB", borderRight: "#FFF solid" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase font-semibold text-center mb-8">
                  Vision
                </h3>
                <p className="leading-7 text-left px-4 mb-4">
                  RCWDO aspires for a poverty free and thriving just society1
                  leaving no one behind.
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="ml-8 align-middle mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-line-target bgicon"></i>
              </div>
            </motion.div>
          </div>

          <div
            className="transparent lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#87CEEB", borderRight: "#FFF solid" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase font-semibold text-center mb-8">
                  Mission
                </h3>
                <p className="leading-7 text-left px-4 mb-4">
                  RCWDO works for robust and resilient community to ensure
                  improved and sustainable livelihood whereby the rights of the
                  poor and vulnerable groups of the societies are promoted and
                  protected through their proactive engagement as owner & change
                  agent.
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="align-middle ml-8 mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-eye bgicon"></i>
              </div>
            </motion.div>
          </div>

          <div
            className="transparent lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#87CEEB" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase font-semibold text-center mb-8">
                  Values
                </h3>
                <p className="laeding-7 text-left px-4 mb-4">
                  Integrity, Trust-Worthiness, Diversity, Community
                  Participation and Partnership Development
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="align-middle ml-8  mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-diamond bgicon"></i>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
  const OurPartners = () => (
    <>
      <div className="text-center mt-8 mb-4">
        <h2 className="text-xl md:text-2xl border-b-2 uppercase font-serif font-bold pb-4 mb-4">
          Partners & Donors
        </h2>
      </div>
      <div className="overflow-hidden px-0 mb-16">
        <Marquee
          pauseOnHover={true}
          gradient={false}
          className="w-full flex items-center justify-center"
        >
          {partners.map((partner, index) => (
            <div key={index} className="mx-4 flex-shrink-0">
              <img
                src={partner.image}
                className="w-28"
                height={30}
                alt={partner.name}
              />
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );

  return (
    <div>
      <Slider />
      <Counter />
      <ProgrammaticArea />
      <MissionVission />
      <RecentNews />
      <BoardMembers />
      <OurPartners />
    </div>
  );
};

export default Home;
