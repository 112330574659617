import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleError, handleSuccess } from "../../../components/Toasts/toasts";
import { server } from "../../../server";
import { toast } from "react-toastify";
//add new message
export const createContactus = createAsyncThunk(
  "contactus",
  async (contactusData, thunkAPI) => {
    try {
      let response = await axios.post(
        `${server}/contactus/create-contactus`,
        contactusData
      );
    
      if (response.status === 201) {
        toast.success("send message successfully!");
        return response.data.contactus;
      } else {
        toast.error("An error occurred. Please try again later.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("internal server error");
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
