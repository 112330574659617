import React, { useState, useEffect } from "react";
import report from "../../assets/images/Report/report.pdf";
import pdfImage from "../../assets/images/Report/pdfimage.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { viewPublication } from "../../States/Action/Publication-Action/PublicationAction";
import Loader from "../commons/loader";

const PublicationPage = () => {
  const dispatch = useDispatch();

  const publications = useSelector((state) => state.publications);


  useEffect(() => {
    dispatch(viewPublication());
  }, []);

  const [publicationReportData, setPublicationReportData] = useState(
    publications || ""
  );
  useEffect(() => {
    const result = publications?.publications?.publications?.filter(
      (curData) => {
        return curData.publication_type === "reports";
      }
    );
    setPublicationReportData(result);
  }, [publications]);

  const [publicationReportReserchData, setPublicationResearchData] = useState(
    publications || ""
  );
  useEffect(() => {
    const result = publications?.publications?.publications?.filter(
      (curData) => {
        return curData.publication_type === "researches";
      }
    );
    setPublicationResearchData(result);
  }, [publications]);

  return (
    <div>
      <div className="container py-8 mx-auto px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
        <div className="border-b border-b-gray-300">
          <h2 className="text-2xl font-semibold mt-8 mb-4 uppercase border-b border-b-gray-300">
            Reports
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-2">
            {publicationReportData?.length > 0 ? (
              publicationReportData?.map((reports, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-4 bg-white shadow rounded"
                >
                  <img
                    src={pdfImage}
                    alt={`Annual Report ${index}`}
                    className="w-full mb-2"
                  />
                  <p className="text-center text-gray-700 mb-2 px-4">
                    {reports.description}
                  </p>
                  {/* {reports.report && ( */}
                  <a
                    href={report}
                    download
                    className="text-blue-500 block mt-2 uppercase"
                  >
                    Download PDF
                  </a>
                  {/* )} */}
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>

        <div className="border-b border-b-gray-300">
          <h2 className="text-2xl font-semibold mt-8 mb-4 uppercase border-b border-b-gray-300">
            Researches
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-2">
            {publicationReportReserchData?.length > 0 ? (
              publicationReportReserchData?.map((reports, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center p-4 bg-white shadow rounded"
                >
                  <img
                    src={pdfImage}
                    alt={`Annual Report ${index}`}
                    className="w-full mb-2"
                  />
                  <p className="text-center text-gray-700 mb-2 px-4">
                    {reports.description}
                  </p>
                  {/* {report.pdfUrl && ( */}
                  <a
                    href={report}
                    download
                    className="text-blue-500 block mt-2 uppercase"
                  >
                    Download PDF
                  </a>
                  {/* )} */}
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationPage;
