import { createSlice } from '@reduxjs/toolkit';
import { viewGalleries, singleGallery } from '../../Action/Gallery-Action/GalleryAction';

const initialState = {
  galleries: [],
  singleGallery: null,
  totalGallery: 0,
  totalPages: 0,
  currentPage: 0,
  loading:false,
  error: null,
  success:false
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewGalleries.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewGalleries.fulfilled, (state, action) => {
        state.loading = false
        state.galleries = action.payload.galleries;
        state.totalGallery = action.payload.totalGallery;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(viewGalleries.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload;
      })
      .addCase(singleGallery.pending, (state) => {
        state.loading = true
      })
      .addCase(singleGallery.fulfilled, (state, action) => {
        state.success = true
        state.singleGallery = action.payload.gallery;
      })
      .addCase(singleGallery.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload;
      });
  },
});

export default gallerySlice.reducer;
