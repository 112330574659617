import React, { useState } from "react";
import {
  MdPhone,
  MdEmail,
  MdFax,
  MdOutlineMarkunreadMailbox,
} from "react-icons/md";
import { motion } from "framer-motion";
import { fadeIn } from "../animations/animation";
import { useDispatch} from "react-redux";
import { createContactus } from "../../States/Action/Contactus-Action/ContactusAction";

const Contactus = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setSuccess(false);

    const resultAction = await dispatch(
      createContactus({
        full_name: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phone,
        message: formData.message,
      })
    );

    if (createContactus.fulfilled.match(resultAction)) {
      // Reset form fields
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        message: '',
      });
      // Set success state
      setSuccess(true);
      // Refresh the page
      window.location.reload();
    } else {
      // Handle the errors if needed
      setErrors([resultAction.payload || 'Submission failed']);
    }
  };


  return (
    <div>
      <div className="relative bg-white mt-16">
        <div className="relative px-0 py-80px overflow-hidden">
          <div className="container clearfix">
            <div className="flex flex-wrap items-stretch col-mb-50 mb-0">
              <div className="w-full lg:w-1/2">
                <div className="max-w-sm mx-auto">
                  <motion.div
                    variants={fadeIn("right", 0.3)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false, amount: 0.3 }}
                    className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                  >
                    <form onSubmit={handleSubmit}>
                      {errors.length > 0 && (
                        <div className="mb-4">
                          {errors.map((error, index) => (
                            <p key={index} className="text-red-600">
                              {error.msg}
                            </p>
                          ))}
                        </div>
                      )}
                      {success && (
                        <div className="mb-4 text-green-600">
                          Your message has been sent successfully!
                        </div>
                      )}
                      <div className="mb-2">
                        <label className="block mb-2 text-sm font-medium uppercase">
                          Full Name <small className="text-red-700">*</small>
                        </label>
                        <input
                          className="py-2 px-4 h-11 w-full text-gray-700 bg-white border border-gray-300 focus:border-yellowGreen-500 shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500"
                          type="text"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="block mb-2 text-sm font-medium uppercase">
                          Email <small className="text-red-700">*</small>
                        </label>
                        <input
                          className="py-2 px-4 h-11 w-full text-gray-700 bg-white border border-gray-300 focus:border-yellowGreen-500 shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="block mb-2 text-sm font-medium uppercase">
                          Phone
                        </label>
                        <input
                          className="py-2 px-4 h-11 w-full text-gray-700 bg-white border border-gray-300 focus:border-yellowGreen-500 shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500"
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-2">
                        <label className="block mb-2 text-sm font-medium uppercase">
                          Message <small className="text-red-700">*</small>
                        </label>
                        <textarea
                          className="py-2 px-4 w-full text-gray-700 bg-white border border-gray-300 focus:border-yellowGreen-500 shadow-sm outline-none ring ring-transparent focus:ring-yellowGreen-500"
                          rows="4"
                          cols="8"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <button
                        className="group mt-8 uppercase relative bg-[#87CEEB] flex items-center justify-center px-5 h-12 w-full font-bold text-black bg-gradient-to-br from-cyanGreen-800 to-cyan-800 transition-all duration-300 focus:outline-none"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </motion.div>
                </div>
              </div>

              <div className="w-full lg:w-1/2 h-[450px] lg:min-h-1/2">
                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                >
                  <div className="relative w-full h-[450px]">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15763.22725237893!2d38.81609937116428!3d9.019386896177782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b851343b203c7%3A0x11685287340e65ee!2sRift%20Valley%20Children%20%26%20Women%20Development%20Organization%20(RCWDO)!5e0!3m2!1sen!2s!4v1621017331243!5m2!1sen!2s"
                      title="map"
                      width="700"
                      height="450"
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="flex w-full justify-center mt-32 mb-32">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                >
                  <div className="relative flex flex-wrap flex-col items-center text-center mt-8 pt-0 px-8 pb-8 border-[1px] border-[solid] border-[#E5E5E5] rounded-[5px]">
                    <MdPhone className="text-[#87CEEB] text-4xl mb-6" />
                    <div className="flex-grow min-w-0 max-w-full px-3">
                      <h3 className="text-1rem font-semibold font-['Poppins',_sans-serif] uppercase mb-0 text-[#333]">
                        Tel
                        <span className="block mt-5px text-[#444] font-light normal-case">
                          +251116478382/+251116478404 <br />
                          <span> or </span>+251165617203/+251165617204
                        </span>
                      </h3>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                >
                  <div className="relative flex flex-wrap flex-col items-center text-center mt-8 pt-0 px-8 pb-8 border-[1px] border-[solid] border-[#E5E5E5] rounded-[5px]">
                    <MdEmail className="text-[#87CEEB] text-4xl mb-6" />
                    <div className="flex-grow min-w-0 max-w-full px-3">
                      <h3 className="text-1rem font-semibold font-['Poppins',_sans-serif] uppercase mb-0 text-[#333]">
                        Email
                        <span className="block mt-5px text-[#444] font-light normal-case">
                          riftvalley@ethionet.et <br />
                          <span className="text-blue-600">or</span>{" "}
                          berhabu@yahoo.com
                        </span>
                      </h3>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                >
                  <div className="relative flex flex-wrap flex-col items-center text-center mt-8 pt-0 px-8 pb-8 border-[1px] border-[solid] border-[#E5E5E5] rounded-[5px]">
                    <MdFax className="text-[#87CEEB] text-4xl mb-6" />
                    <div className="flex-grow min-w-0 max-w-full px-3">
                      <h3 className="text-1rem font-semibold font-['Poppins',_sans-serif] uppercase mb-0 text-[#333]">
                        Fax
                        <span className="block mt-5px text-[#444] font-light normal-case">
                          +25116478383
                        </span>
                      </h3>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  variants={fadeIn("right", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: false, amount: 0.3 }}
                  className="flex flex-col gap-y-12 mb-10 lg:mb-0"
                >
                  <div className="relative flex flex-wrap flex-col items-center text-center mt-8 pt-0 px-8 pb-8 border-[1px] border-[solid] border-[#E5E5E5] rounded-[5px]">
                    <MdOutlineMarkunreadMailbox className="text-[#87CEEB] text-4xl mb-6" />
                    <div className="flex-grow min-w-0 max-w-full px-3">
                      <h3 className="text-1rem font-semibold font-['Poppins',_sans-serif] uppercase mb-0 text-[#333]">
                        P.O. Box
                        <span className="block mt-5px text-[#444] font-light normal-case">
                          12916/22998, Addis Ababa, Ethiopia
                        </span>
                      </h3>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
