import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo-image.png";
import DonationModal from "./DonateModal";
import Marquee from "react-fast-marquee";
import Brand from "../../assets/images/brand.jpg";

function Header() {
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);

  const handleItemClick = (item) => {
    setActiveItem(item);
    setOpen(false);
  };

  return (
    <>
      <div className="relative h-8 bg-white flex items-center justify-center px-4 top-0 left-0 right-0">
        <Marquee gradient={false} className="w-full text-center">
          <span className="text-center text-blue-700 md:text-xl md:text-semibold">
            Increased people’s capabilities and their institutions for sustained
            development
          </span>
        </Marquee>
      </div>
      <div className="sticky top-0 px-0 py-0 z-50 bg-[#87CEEB] w-full shadow border-b border-gray-400">
        <div className="flex items-center justify-between h-auto md:h-28 lg:h-32 font-bold">
          <div className="flex flex-between sm:ml-0">
            <img
              src={Logo}
              height={180}
              width={160}
              alt="logo"
              className="cursor-pointer"
            />
            <div>
              <img
                src={Brand}
                height={200}
                width={300}
                alt="brand"
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="hidden md:flex items-center">
            <ul className="flex items-center gap-8 text-sm md:text-md lg:text-xl xl:text-2xl 2xl:text-2x font-serif">
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/"
                  text="Home"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                />
              </span>
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/aboutus"
                  text="About us"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                />
              </span>
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/publication"
                  text="Publication"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                  className="hover:bg-yellow-600"
                />
              </span>
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/news"
                  text="News & Updates"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                />
              </span>
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/gallery"
                  text="Gallery"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                />
              </span>
              <span className="hover:text-yellow-600">
                <NavItem
                  to="/contactus"
                  text="Contact Us"
                  activeItem={activeItem}
                  onItemClick={handleItemClick}
                />
              </span>
            </ul>
            <button
              onClick={() => setIsDonationModalOpen(true)}
              className="py-8 px-6 hover:bg bg-yellow-600 md:text-md lg_text-lg xl:text-xl  2xl:text-2xl inline-block focus:outline-none"
            >
              Donate Now
            </button>
          </div>
          <div className="block md:hidden">
            {!open ? (
              <button onClick={() => setOpen(true)}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            ) : (
              <button onClick={() => setOpen(false)}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        {open && (
          <ul className="md:hidden bg-[#87CEEB] fixed w-full top-0 bottom-0 py-24 pl-4">
            <span className="hover:text-yellow-600">
              <NavItem
                to="/"
                text="Home"
                activeItem={activeItem}
                onItemClick={handleItemClick}
                className="hover:text-yellow-600"
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                to="/aboutus"
                text="About us"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                to="/publication"
                text="Publication"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                to="/news"
                text="News & Updates"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                to="/gallery"
                text="Gallery"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                to="/contactus"
                text="Contact Us"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
            <span className="hover:text-yellow-600">
              <NavItem
                onClick={() => setIsDonationModalOpen(true)}
                text="Donate"
                activeItem={activeItem}
                onItemClick={handleItemClick}
              />
            </span>
          </ul>
        )}
        {isDonationModalOpen && (
          <DonationModal onClose={() => setIsDonationModalOpen(false)} />
        )}
      </div>
    </>
  );
}

function NavItem({ to, text, activeItem, onItemClick, onClick }) {
  const isActive = activeItem === to;
  return (
    <li>
      {to ? (
        <Link
          to={to}
          onClick={() => onItemClick(to)}
          className={`py-7 px-3 inline-block ${
            isActive ? "border-b-2 border-[#b2b425]" : ""
          }`}
        >
          {text}
        </Link>
      ) : (
        <button
          onClick={onClick}
          className={`py-7 px-3 inline-block ${
            isActive ? "border-b-2 border-[#b2b425]" : ""
          }`}
        >
          {text}
        </button>
      )}
    </li>
  );
}

export default Header;
