import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdDateRange, MdLocationOn } from "react-icons/md";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { backend_url } from "../../server";
import { viewGalleries } from "../../States/Action/Gallery-Action/GalleryAction";
import Loader from "../commons/loader";

const Gallery = () => {
  const dispatch = useDispatch();
  const { galleries, totalPages, loading, error } = useSelector((state) => state.galleries);
  //alert(JSON.stringify(totalPages))

  const [selectedGallery, setSelectedGallery] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(viewGalleries({ currentPage, searchTerm }));
  }, [dispatch, currentPage, searchTerm]);

  const handleTextClick = (selectedItem) => {
    setSelectedGallery(selectedItem);
  };

  const handleClosePopup = () => {
    setSelectedGallery(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container py-8 mx-auto px-4">
        <h1 className="text-3xl uppercase font-bold mb-4">Gallery</h1>
        <input
          type="text"
          placeholder="Search gallery..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="border border-gray-300 rounded px-4 py-2 w-full mb-4"
        />
        {error && <p className="text-red-500 mb-4">{error.message}</p>}
        <div className="flex flex-wrap justify-center -mx-4 hover:transition-all duration-500">
          {loading ? (
            <Loader />
          ) : (
            galleries &&
            galleries.length > 0 &&
            galleries.map((item, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-8"
              >
                <div className="relative">
                  <Zoom>
                    <img
                      src={`${backend_url}/${item?.gallery}`}
                      alt={`slide-${index + 1}`}
                      className="cursor-pointer w-full h-64 object-cover rounded-lg"
                    />
                  </Zoom>
                </div>
                <div
                  className="mt-3 cursor-pointer"
                  onClick={() => handleTextClick(item)}
                >
                  <h3 className="mb-1 text-lg font-serif font-semibold text-blue-700">
                    {item.title}
                  </h3>
                  <p className="mb-1 text-lg font-semibold text-blue-700">
                    {item.description}
                  </p>
                  <div className="flex items-center">
                    <div className="flex items-center mr-4">
                      <span className="mr-2 text-yellow-600 text-2xl">
                        <MdDateRange />
                      </span>
                      <span className="text-yellow-600 text-sm">
                        {item.createdAt.substring(0, 10)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="mr-2 text-yellow-600 text-2xl">
                        <MdLocationOn />
                      </span>
                      <span className="text-yellow-600 text-sm">
                        {item.location} || null
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="flex justify-center mt-4">
          <button
            onClick={handlePrevPage}
            className="px-3 py-1 rounded bg-blue-500 text-white mr-2"
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <span>
            {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            className="px-3 py-1 rounded bg-blue-500 text-white ml-2"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      {selectedGallery && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-4 sm:p-8 rounded-lg max-w-screen-lg overflow-hidden relative">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <img
                  src={`${backend_url}/${selectedGallery.gallery}`}
                  alt={selectedGallery.altText}
                  className="w-full h-auto object-cover rounded-lg"
                />
              </div>
              <div>
                <h2 className="text-2xl font-semibold mb-4">
                  {selectedGallery.title}
                </h2>
                <p className="mb-4">{selectedGallery.description}</p>
                <div className="flex items-center">
                  <div className="flex items-center mr-4">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdDateRange />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {selectedGallery.createdAt.substring(0, 10)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdLocationOn />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {selectedGallery.location}
                    </span>
                  </div>
                </div>
                <button
                  onClick={handleClosePopup}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
