// navigation Data
import slider1 from '../assets/slider/photo1.jpg';
import slider2 from '../assets/slider/photo2.jpg';
import slider3 from '../assets/slider/photo3.jpg';
import slider4 from '../assets/slider/photo4.jpg';

export const placeholderImages = {
  slider1: {
    image: slider1,
    title: "improved livelihood of the resource poor households",
    description: "Ensure improved livelihood of the resource poor households & the most disadvantaged communities and increase their resilience capabilities"
  },
  slider2: {
    image: slider2,
    title: "Enhance the educational status of the communities",
    description: "enhance the educational status of the communities & increase learners’ performance particularly children, women & the disadvantaged groups of the society"
  },
  slider3: {
    image: slider3,
    title: "reduce the vulnerability & mortality of children, women",
    description: "Reduce the vulnerability & mortality of children, women & other disadvantaged groups in the community"
  },
  slider4: {
    image: slider4,
    title: "Ensure gender equity, equality & empowerment & protection of women and children",
    description: " Ensure gender equity, equality & empowerment & protection of women and children in the community in hastening & sustaining their development"
  },
}
  export const footerProductLinks = [
    {
      name: "About Us",
      link: "/aboutus"
    },
   
    {
      name: "Our News",
      link:"/news"
    },
  ];
  
  export const footercompanyLinks = [
    {
      name: "Community Support",
      link:"/aboutus"
    },
    {
      name: "Community development",
      link:"/aboutus"
    },
   
  ];
  
  export const footerSupportLinks = [

    {
      name: "Call us",
      link:"/contactus"
    },
    {
      name: "Contact Us",
      link:"/contactus"
    }
 
  ];


  