import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleError } from '../../../components/Toasts/toasts';
import { server } from '../../../server';
import { toast } from 'react-toastify';

// List all galleries
export const viewGalleries = createAsyncThunk('gallery/viewGalleries', async ({ currentPage, searchTerm }, thunkAPI) => {
  try {
    let response = await axios.get(
      `${server}/galleries/paginate-public?page=${currentPage}&search=${searchTerm}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error('Network error');
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {
    toast.error("Please try again. this is Network error");
    return thunkAPI.rejectWithValue(e.response ? e.response.data : { error: "Network error" });
  }
});

// Fetch a single gallery by ID
export const singleGallery = createAsyncThunk('gallery/singleGallery', async (id, thunkAPI) => {
  try {
    const response = await axios.get(`${server}/galleries/public/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      handleError(response.message);
      return thunkAPI.rejectWithValue(response.data);
    }
  } catch (e) {

    toast.error("Please try again. this is Network error");
    return thunkAPI.rejectWithValue(e.response ? e.response.data : { error: "Network error" });
  }
});
