import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

// List all contents
export const viewContents = createAsyncThunk(
  "contents/viewContents",
  async ({ currentPage, searchTerm }, thunkAPI) => {
    try {
      let response = await axios.get(
        `${server}/contents/paginate-public?page=${currentPage}&search=${searchTerm}`
      );
      //console.log("response : ", response);
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(
        e.response ? e.response.data : { error: "Network error" }
      );
    }
  }
);
// List all contents
export const viewContent = createAsyncThunk(
  "contents/viewContents",
  async ({ currentPage, searchTerm }, thunkAPI) => {
    try {
      let response = await axios.get(`${server}/contents/no-paginate-public`);

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(
        e.response ? e.response.data : { error: "Network error" }
      );
    }
  }
);

// Fetch a single content by ID
export const singleContent = createAsyncThunk(
  "contents/singleContent",
  async (id, thunkAPI) => {
    const token = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null;
    try {
      const response = await axios.get(`${server}/contents/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(
        e.response ? e.response.data : { error: "Network error" }
      );
    }
  }
);
