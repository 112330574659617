import React, { useState, useEffect } from "react";
import Partners from "./Partners";
import {
  FaTrophy,
  FaHandshake,
  FaUsers,
  FaComments,
  FaBalanceScale,
  FaPeopleCarry,
  FaShieldAlt,
  FaFistRaised,
  FaGavel,
  FaUserCircle,
  FaCheck,
} from "react-icons/fa";
import Photo from "../../assets/images/about.jpg";
import { useDispatch, useSelector } from "react-redux";
import { backend_url } from "../../server";
import { viewBoardmembers } from "../../States/Action/Boardmember-Action/BoardmemberAction";
import Loader from "../commons/loader";

const AboutUs = () => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  // Sample data for board members

  const values = [
    { icon: <FaTrophy />, title: "Excellence in Performance" },
    { icon: <FaHandshake />, title: "Partnership" },
    { icon: <FaUsers />, title: "Team Work" },
    { icon: <FaComments />, title: "Open Communication & Learning" },
    { icon: <FaBalanceScale />, title: "Accountability & Transparency" },
    { icon: <FaPeopleCarry />, title: "Diversity and Inclusiveness" },
    { icon: <FaShieldAlt />, title: "Integrity and Honesty" },
    { icon: <FaFistRaised />, title: "Empowerment & Solidarity" },
    { icon: <FaGavel />, title: "Equality, equity & human right" },
    { icon: <FaUserCircle />, title: "People Centered" },
  ];

  const calculateWidthClass = (length) => {
    switch (length) {
      case 1:
        return "w-full";
      case 2:
        return "w-full md:w-1/2";
      case 3:
        return "w-full md:w-1/2 lg:w-1/3";
      default:
        return "w-full md:w-1/2 lg:w-1/3 xl:w-1/4";
    }
  };

  const { boardmember } = useSelector((state) => state.boardmember);

  useEffect(() => {
    dispatch(viewBoardmembers());
  }, []);

  useEffect(() => {
    const paragraphHeight =
      document.querySelector(".about-paragraph").clientHeight;
    setImageHeight(paragraphHeight);
  }, []);

  const ObjectiveMissionVision = () => (
    <div className="py-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl border-b font-bold ml-8 mb-4 uppercase">
            Vision
          </h2>
          <div className="flex mb-2">
            <FaCheck className="mr-2 mt-1 text-green-500" />
            <p className="mb-0">
              RCWDO aspires for a poverty free and thriving just society1
              leaving no one behind.
            </p>
          </div>
        </div>
        <div className="mb-8">
          <h2 className="text-2xl border-b  ml-8 font-bold mb-4 uppercase">
            Mission
          </h2>
          <div className="flex mb-2">
            <FaCheck className="mr-2 mt-1 text-green-500" />
            <p className="mb-0">
              RCWDO works for robust and resilient community to ensure improved
              and sustainable livelihood whereby the rights of the poor and
              vulnerable groups of the societies are promoted and protected
              through their proactive engagement as owner & change agent.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-4 border-b border-gray-200">
        <h2 className="text-2xl border-b ml-8 font-bold mb-8 uppercase">
          ORGANIZATIONAL GOALS/ OVERALL ORGANIZATIONAL OBJECTIVES
        </h2>
        <div className="flex mb-2">
          <FaCheck className="mr-2 mt-1 text-green-500" />
          <p className="mb-0">
            In line with its mission, RCWDO works to strategically address the
            critical and interlocking problems of the soecity such as pverty,
            igonrance, illness and inequalities for just society. Accrdinly it
            has organizational goals of:
          </p>
        </div>
        <div className="flex mb-2">
          <FaCheck className="mr-2 mt-1 text-green-500" />
          <p className="mb-0">
            To ensure improved livelihoods & resilience capacity of the poor and
            vulnerable people through inclusive, climate smart, age & gender
            sensitive and sustainable innovative solutions.
          </p>
        </div>
        <div className="flex mb-2">
          <FaCheck className="mr-2 mt-1 text-green-500" />
          <p className="mb-0">
            To enhance and advocate for inclusive and quality education and
            skills development of children, women, youth, and other vulnerable
            groups to improve educational performance and sustainable employment
            creation.
          </p>
        </div>
        <div className="flex mb-4">
          <FaCheck className="mr-2 mt-1 text-green-500" />
          <p className="mb-0">
            To amplify the voices and protection of the rights of people
            particularly the most vulnerable groups of the society to end
            inequalities and ensure empowerment through access to inclusive
            quality basic services and promotion of good governance.
          </p>
        </div>
        <div className="flex mb-4">
          <FaCheck className="mr-2 mt-1 text-green-500" />
          <p className="mb-0">
            To promote provision of safe, inclusive, and responsive lifesaving
            humanitarian assistance for victims of manmade crisis and natural
            disasters through proactive engagement & leadership of affected
            people and vulnerable groups by strengthening the resilience for
            recovery.
          </p>
        </div>
      </div>
    </div>
  );

  const CoreValues = () => (
    <div className="text-center border-b border-gray-200">
      <h2 className="text-2xl uppercase border-b-2 font-bold pb-4 mb-4">
        Core values
      </h2>
      <div className="flex flex-wrap justify-center lg:justify-start lg:-mx-4 mt-4">
        {values.map((value, index) => (
          <div
            key={index}
            className={`${calculateWidthClass(values.length)} px-2 mb-8`}
          >
            <div className="p-4 rounded-lg h-full flex flex-col">
              <div className="flex items-center justify-center h-24 text-8xl text-[#B7CEFA]">
                {value.icon}
              </div>
              <h3 className="text-xl font-bold mt-4">{value.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const BoardMembers = () => (
    <>
      <div className="text-center mt-4">
        <h2 className="text-xl md:text-2xl border-b-2 font-bold uppercase pb-4 mb-4">
          Board Members
        </h2>
      </div>
      <div className="flex flex-wrap bg-[#87CEEB] justify-center mt-8">
        {/* Loop through board members data and render member cards */}
        {boardmember && boardmember?.boardmember?.length > 0 ? (
          boardmember?.boardmember.slice(0, 8).map((member, index) => (
            <div key={index} className="w-full md:w-1/4 p-4">
              <div
                className="rounded-lg overflow-hidden flex flex-col justify-center items-center py-6 h-full"
                // style={{ height: "100%" }}
              >
                <div className="w-full h-full flex justify-center items-center overflow-hidden">
                  <img
                    src={`${backend_url}/${member.board_member_photo}`}
                    alt="Board Member"
                    className="object-cover h-full w-full" // Ensure image covers the container and maintains aspect ratio
                  />
                </div>
                <h3 className="text-xl font-semibold my-2">
                  {member?.board_member_name}
                </h3>
                <p className="text-gray-700">
                  {member?.board_member_profession}
                </p>
              </div>
            </div>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
  const AboutUs = () => (
    <div className="relative bg-white py-10 lg:py-20 leading-relaxed border-b border-gray-200">
      <div className="py-4">
        <h2 className="text-2xl font-bold mb-4  border-b border-gray-200 uppercase font-serif">
          About Rift Valley Children and Women Development Organization
        </h2>
        <div className="flex flex-col md:flex-row items-start">
          <div className="md:w-1/2 mb-4 md:mb-0 order-2 md:order-2 relative">
            <img
              src={Photo}
              alt="RCWDO"
              style={{ height: imageHeight }}
              className="h-full w-full object-cover"
            />
          </div>
          <div className="md:w-1/2 order-1 md:order-1">
            <p className="mb-4 about-paragraph">
              RCWDO aspires for a poverty free and thriving just society1
              leaving no one behind. RCWDO works for robust and resilient
              community to ensure improved and sustainable livelihood whereby
              the rights of the poor and vulnerable groups of the societies are
              promoted and protected through their proactive engagement as owner
              & change agent. ORGANIZATIONAL GOALS/ OVERALL ORGANIZATIONAL
              OBJECTIVES. In line with its mission, RCWDO works to strategically
              address the critical and interlocking problems of the soecity such
              as pverty, igonrance, illness and inequalities for just society.
              Accrdinly it has organizational goals of: Ensure improved
              livelihoods & resilience capacity of the poor and vulnerable
              people through inclusive, climate smart, age & gender sensitive
              and sustainable innovative solutions. Enhance and advocate for
              inclusive and quality education and skills development of
              children, women, youth, and other vulnerable groups to improve
              educational performance and sustainable employment creation.
              Amplify the voices and protection of the rights of people
              particularly the most vulnerable groups of the society to end
              inequalities and ensure empowerment through access to inclusive
              quality basic services and promotion of good governance. Promote
              provision of safe, inclusive, and responsive lifesaving
              humanitarian assistance for victims of manmade crisis and natural
              disasters through proactive engagement & leadership of affected
              people and vulnerable groups by strengthening the resilience for
              recovery.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="container mx-auto px-4 lg:px-8">
        <AboutUs />
        <section id="content" className="py-12">
          <div className="flex flex-wrap justify-between mb-8">
            <ObjectiveMissionVision />
          </div>
        </section>
        <CoreValues />
        <BoardMembers />
        <span className="mb-16 h-24">
          <Partners />
        </span>
      </div>
    </>
  );
};

export default AboutUs;
