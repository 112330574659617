import React from "react";
import UNHCR from "../../assets/partnersanddonors/1.HelpAge.png";
import USAID from "../../assets/partnersanddonors/2. IRC.png";
import IGAD from "../../assets/partnersanddonors/3. ESAP.png"
import OCHA from "../../assets/partnersanddonors/4. Action Aid.png";
import IOM from "../../assets/partnersanddonors/5. KNH.png";
import IRC from "../../assets/partnersanddonors/6. CDP.jpg";
import CARE from "../../assets/partnersanddonors/7. Imagine1day.jpg";
import WV from "../../assets/partnersanddonors/8. BMZ.png";
import GLRA from "../../assets/partnersanddonors/9. PCI.png";
import WFP from "../../assets/partnersanddonors/10. IIRR.png";
import FRC from "../../assets/partnersanddonors/11. HoRAEC.jpg";
import Coopi from "../../assets/partnersanddonors/12. Oxfam GB.png";
import COOP from "../../assets/partnersanddonors/13. EU civil society fund.png";

import MFM from "../../assets/partnersanddonors/14. MFM.png";
import CSSP2 from "../../assets/partnersanddonors/15. CSSP2.png";
import AJWS from "../../assets/partnersanddonors/16. American Jewish World Service.png"
import CCRDA from "../../assets/partnersanddonors/17. CCRDA.png";
import OXFM from "../../assets/partnersanddonors/18. Oxfam Intermon.png";
import OXFMA from "../../assets/partnersanddonors/20. Oxfam America.png";
import TU from "../../assets/partnersanddonors/21. Trickle Up.png";
import HC from "../../assets/partnersanddonors/22. Help a child.png";
import NZ from "../../assets/partnersanddonors/23. Netherland embassy.png";
import NE from "../../assets/partnersanddonors/24. Norwegian Embassy.png";
import FE from "../../assets/partnersanddonors/25. French Embassy.png";

import Marquee from 'react-fast-marquee';
//import partnerImages from "../assets/images/Partner";

const Partners = () => {
  const partners = [
    { name: "UNHCR", image:UNHCR },
    { name: "USAID", image:USAID },
    { name: "IGAD", image:IGAD },
    { name: "OCHA", image:OCHA },
    { name: "IOM", image:IOM },
    { name: "IRC", image:IRC },
    { name: "CARE", image:CARE },
    { name: "WV", image:WV },
    { name: "GLRA", image:GLRA },
    { name: "WFP", image:WFP },
    { name: "FRC", image:FRC },
    { name: "Coopi", image:Coopi},
    { name: "COOP", image:COOP },

    { name: "UNHCR", image:MFM },
    { name: "USAID", image:CSSP2 },
    { name: "IGAD", image:AJWS },
    { name: "OCHA", image:CCRDA },
    { name: "IOM", image:OXFM },
    { name: "IRC", image:OXFMA },
    { name: "CARE", image:TU },
    { name: "WV", image:HC },
    { name: "GLRA", image:NZ },
    { name: "WFP", image:NE },
    { name: "FRC", image:FE },
  ];

  const calculateContainerWidth = () => {
  
    const partnerCount = partners.length;
    return partnerCount * 120; 
  };
  return (
    <>
    <div className="text-center mt-8 mb-4">
    <h2 className="text-2xl border-b-2 font-bold pb-4 mb-4 uppercase">Partners & Doners</h2>
  </div>
      <div className="overflow-hidden px-0 mb-16">
        <Marquee  pauseOnHover="true" style={{ width: calculateContainerWidth() }}>
          {partners.map((partner, index) => (
            <div key={index} className="mx-2">
              <img src={partner.image} className="w-28" height={30} alt={partner.name} />
            </div>
          ))}
        </Marquee>
      </div>
      </>

  );
};

export default Partners;
