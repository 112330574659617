import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { viewPublication } from "../../Action/Publication-Action/PublicationAction";

const initialState = {
  publications: {},
  publications: [],
  loading: false,
  error: null,
  success: false,
};

const publicationsSlice = createSlice({
  reducers: {},
  name: "publications",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(viewPublication.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewPublication.fulfilled, (state, action) => {
        state.loading = false;
        state.publications = action.payload;
      })
      .addCase(viewPublication.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default publicationsSlice.reducer;
