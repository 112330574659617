import React from 'react'
import { Link } from "react-router-dom";
import { fadeIn } from "../animations/animation";
import { motion } from "framer-motion";

const Mission_vission = () => {
  return (
    <div>
         <div className="flex flex-wrap bottommargin-lg items-stretch mt-8">
        <div className="flex flex-wrap bottommargin-lg items-stretch mt-8">
          <div
            className="lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#B7CEFA", borderRight: "#FFF solid" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase text-black font-semibold text-center mb-8">
                  Vision
                </h3>
                <p className="leading-7 text-black text-left px-4 mb-4">
                  Facilitate humanitarian and development support actively
                  working with the most vulnerable in their effort to enhance
                  protection and solutions with no regard to race, religion or
                  political affiliation
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="ml-8 align-middle mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-line-target bgicon"></i>
              </div>
            </motion.div>
          </div>

          <div
            className="transparent lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#B7CEFA", borderRight: "#FFF solid" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase text-black font-semibold text-center mb-8">
                  Mission
                </h3>
                <p className="leading-7 text-black text-left px-4 mb-4">
                  RCWDO works for robust & thriving communities particularly the
                  hard to reach & vulnerable segment of the society as well as
                  the disadvantaged people to enjoy sustainable livelihoods
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="align-middle ml-8 mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-eye bgicon"></i>
              </div>
            </motion.div>
          </div>

          <div
            className="transparent lg:w-1/3 pr-4 pl-4 dark col-padding overflow-hidden flex flex-col justify-center items-center py-6"
            style={{ backgroundColor: "#B7CEFA" }}
          >
            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <div>
                <h3 className="uppercase text-black font-semibold text-center mb-8">
                  Values
                </h3>
                <p className="laeding-7 text-black text-left px-4 mb-4">
                  Integrity, Trust-Worthiness, Diversity, Community
                  Participation and Partnership Development
                </p>
                <Link to={"/aboutus"}>
                  <button
                    className="align-middle ml-8  mb-8 select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-3.5 px-7 rounded-lg border border-white text-black hover:opacity-75 focus:ring focus:ring-blue-gray-200 active:opacity-[0.85] flex items-center gap-3 mt-4"
                    type="button"
                  >
                    Discover More
                  </button>
                </Link>
                <i className="icon-diamond bgicon"></i>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission_vission