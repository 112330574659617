import { configureStore } from "@reduxjs/toolkit";
import GalleryReducer from "../States/Reducer/Gallery-Reducer/GalleryReducer";
import ContactusReducer from "../States/Reducer/Contactus-Reducer/ContactusReducer";
import ContentReducer from "../States/Reducer/Content-Reducer/ContentReducer";
import PublicationReducer from "../States/Reducer/Publication-Reducer/PublicationReducer";
import BoardmemberReducer from "../States/Reducer/Boardmember-Reducer/BoardmemberReducer";
const store = configureStore({
  reducer: {
    galleries: GalleryReducer,
    contactus: ContactusReducer,
    contents: ContentReducer,
    publications: PublicationReducer,
    boardmember: BoardmemberReducer,
  },
});

export default store;
