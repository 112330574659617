import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createContactus } from "../../Action/Contactus-Action/ContactusAction";

const initialState = {
  contactus: {},
  contactus: [],
  loading: false,
  error: null,
  success: false,
};

const contactusSlice = createSlice({
  reducers: {},
  name: "contactus",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createContactus.pending, (state) => {
        state.loading = true;
      })
      .addCase(createContactus.fulfilled, (state, action) => {
        state.loading = false;
        state.contactus = action.payload;
      })
      .addCase(createContactus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contactusSlice.reducer;
