import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";

const Footer = () => {
  return (
    <div className="bg-[#87CEEB] text-black">
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <p className="text-black">Our social media Links.</p>
          <div className="flex items-center mt-[15px]">
            <a href='https://www.facebook.com/profile.php?id=61557894866351'>
            <AiFillFacebook
             size={20}
             className="cursor-pointer hover:text-blue-700" />
             </a>
            <AiOutlineTwitter
              size={20}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
            <AiFillInstagram
              size={20}
              style={{ marginLeft: "15px", cursor: "pointer", color:"hover-red"}}
            />
            <AiFillYoutube
              size={20}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            />
          </div>
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 hover:text-yellow-600 font-semibold">Contacts us</h1>
        
            <li  className="hover:text-yellow-600
                   text-sm md:text-md cursor-pointer leading-6">
              P.O. Box: 12916/22998
            </li>

            <li  className="hover:text-yellow-600
                   text-sm md:text-md cursor-pointer leading-6">
                    Tel.: +251116478382/+251116478404 or +251165617203/+251165617204
              
            </li>

            <li  className="hover:text-yellow-600
                   text-sm md:text-md cursor-pointer leading-6">
                    Fax.: +25116478383
              
            </li>

            <li  className="hover:text-yellow-600
                   text-sm md:text-md cursor-pointer leading-6">
                    Email: riftvalley@ethionet.et or berhabu@yahoo.com
              
            </li>
          
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 hover:text-yellow-600 font-semibold">Services</h1>
          {footercompanyLinks.map((link, index) => (
            <li key={index}>
              <Link
                className="hover:text-yellow-600
                   text-sm  md:text-md cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 hover:text-yellow-600 font-semibold">Support</h1>
          {footerSupportLinks.map((link, index) => (
            <li key={index}>
              <Link
                className="hover:text-yellow-600
                   text-sm  md:text-md cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-center border border-b border-gray-400 text-center pt-2 text-sm pb-8">
        <span className="hover:text-yellow-600 space-x-2">©2024 All Rights Reserved By Rift Valley Children and Women Development Organization (RCWDO).</span> 
      </div>
    </div>
  );
};

export default Footer;