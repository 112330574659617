import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'

const Layout = () => {
  return (
    <div>
      {/* Add your header, sidebar, or other layout components here */}
      <header><Header/></header>
      <main>
        <Outlet />
      </main>
      {/* Add your footer here */}
      <footer><Footer/></footer>
    </div>
  );
};

export default Layout;
