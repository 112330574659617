
import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../animations/animation";

const DonationModal = ({ onClose }) => {
    return (
      <div className="fixed top-20 right-4 flex flex-col items-end">
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className="flex flex-col gap-y-12 mb-10 lg:mb-0"
        >
          <div className="bg-white p-4 rounded shadow-md w-96">
            <h2 className="text-xl font-semibold text-blue-500 mb-4">
              Donate For Rift Valley Children and Women Development Organization
            </h2>
            <hr/>
            <div className="flex flex-col gap-2">
            <h3 className="p-2 border-b border-gray-300 rounded text-blue-500">
                {" "}
                CBE, Lamberet Branch{" "}
              </h3>

              <h3 className="p-2 border-b border-gray-300 rounded text-blue-500">
              Act. # 1000511942865{" "}  
              </h3>
              <h3 className="p-2 border-b border-gray-300 rounded text-blue-500">
                {" "}
                Swift_code, CBETETAA{" "}
              </h3>
            </div>
            <button onClick={onClose} className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
              Close
            </button>
          </div>
        </motion.div>
      </div>
    );
  };
  export default DonationModal;