import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/pages/Home";
import News from "./components/pages/News";
import AboutUs from "./components/pages/Aboutus";
import Publication from "./components/pages/Publication";
import Gallery from "./components/pages/Gallery";
import Contactus from "./components/pages/Contactus";
import Layout from "./components/layout";
import NotFound404 from "./components/pages/404";

const App = () => {
  return (
    <>
      <Routes>
        {/* Routes that use the Layout component */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="publication" element={<Publication />} />
          <Route path="news" element={<News />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contactus" element={<Contactus />} />
          {/* Catch-all route inside the layout */}
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Route>

        {/* Route for NotFound404 outside of the Layout */}
        <Route path="/404" element={<NotFound404 />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
