import { createSlice } from "@reduxjs/toolkit";
import { viewContents, singleContent } from "../../Action/Content-Action/ContentAction";


const initialState = {
  contents: [],
  singlecontent: null,
  totalContent: 0,
  totalPages: 0,
  currentPage: 0,
  loading:false,
  error: null,
  success:false
};

const contentsSlice = createSlice({
  name: "contents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewContents.pending, (state) => {
        state.loading = true;
      })
      .addCase(viewContents.fulfilled, (state, action) => {
        state.loading = false
        state.contents = action.payload.contents;
        state.totalContent = action.payload.totalContent;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(viewContents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(singleContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleContent.fulfilled, (state, action) => {
        state.loading = false;
        state.singelContent = action.payload;
      })
      .addCase(singleContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contentsSlice.reducer;
