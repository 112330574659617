import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { viewContents } from "../../States/Action/Content-Action/ContentAction";
import { MdDateRange, MdLocationOn } from "react-icons/md";
import { motion } from "framer-motion";
import { fadeIn } from "../animations/animation";
import Loader from "../commons/loader";
import { backend_url } from "../../server";

const News = () => {
  const dispatch = useDispatch();
  const { contents, totalPages, loading } = useSelector(
    (state) => state.contents
  );

  const [selectedNews, setSelectedNews] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(viewContents({ currentPage, searchTerm }));
  }, [dispatch, currentPage, searchTerm]);

  const handleCardClick = (selectedItem) => {
    setSelectedNews(selectedItem);
  };

  const handleClosePopup = () => {
    setSelectedNews(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container py-8 mx-auto">
        <h1 className="text-3xl uppercase font-bold mb-4">News Release</h1>
        {/* <input
          type="text"
          placeholder="Search news..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="border border-gray-300 rounded px-4 py-2 w-full mb-4"
        /> */}

        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          animate="show"
          viewport={{ once: false, amount: 0.3 }}
          className="flex flex-col gap-y-12 mb-10 lg:mb-0"
        >
          <div className="flex flex-wrap justify-center hover:transition-all duration-500">
            {loading ? (
              <Loader />
            ) : (
              contents &&
              contents.length > 0 &&
              contents.map((item, index) => (
                <div
                  key={index}
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-8"
                >
                  <div className="relative">
                    <img
                      src={`${backend_url}/${item.media_file}`}
                      alt={item.altText || "News image"}
                      style={{ height: "400px", width: "400px" }}
                      onClick={() => handleCardClick(item)}
                    />
                  </div>
                  <div className="mt-3">
                    <h3 className="mb-1 text-xl font-bold text-blue-700">
                      {item.title}
                    </h3>
                    <p className="mb-1 text-lg font-semibold text-blue-700">
                      {item.description.substring(0, 100)}
                      <span>...</span>
                    </p>
                    <div className="flex items-center">
                      <div className="flex items-center mr-4">
                        <span className="mr-2 text-yellow-600 text-2xl">
                          <MdDateRange />
                        </span>
                        <span className="text-yellow-600 text-sm">
                          {item.createdAt.substring(0, 10)}
                        </span>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-2 text-yellow-600 text-2xl">
                          <MdLocationOn />
                        </span>
                        <span className="text-yellow-600 text-sm">
                          {item.location}|| null
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={handlePrevPage}
              className="px-3 py-1 rounded bg-blue-500 text-white mr-2"
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <span>
              {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              className="px-3 py-1 rounded bg-blue-500 text-white ml-2"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </motion.div>
      </div>
      {/* Popup */}
      {selectedNews && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg max-w-screen overflow-hidden">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <img
                  src={
                    selectedNews.photo ||
                    `${backend_url}/${selectedNews.media_file}`
                  }
                  alt={selectedNews.altText || "News image"}
                  className="w-full"
                />
              </div>
              <div>
                <h2 className="text-2xl font-semibold mb-4">
                  {selectedNews.title}
                </h2>
                <div style={{ height: "400px", overflowY: "auto" }}>
                  <p>{selectedNews.description}</p>
                </div>
                <div className="flex items-center mt-4">
                  <div className="flex items-center mr-4">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdDateRange />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {selectedNews.createdAt.substring(0, 10)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2 text-yellow-600 text-2xl">
                      <MdLocationOn />
                    </span>
                    <span className="text-yellow-600 text-sm">
                      {selectedNews.location || "Unknown"}
                    </span>
                  </div>
                </div>
                <button
                  onClick={handleClosePopup}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
