import React from 'react';
import ErrorGif from '../../assets/404.gif';

const NotFound404 = () => {
  return (
    <div className="flex flex-col items-center justify-center m-2 p-2">
      <div>
        <img src={ErrorGif} alt="error" className="max-w-full" />
      </div>
      <div className="mt-4">
        <h5 className="text-xl text-blue-600 text-center">
          Oops! Requested resource does not exist
        </h5>
      </div>
    </div>
  );
};

export default NotFound404;
