// reduxjs toolkit
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../server";
import { toast } from "react-toastify";

//List all Publication
export const viewPublication = createAsyncThunk(
  "publications",
  async (thunkAPI) => {

    try {
      let response = await axios.get(`${server}/publications/paginate-public`);
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Please try again.");
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (e) {
      toast.error("Please try again. this is Network error");
      return thunkAPI.rejectWithValue(e.response ? e.response.data : { error: "Network error" });
    }
  }
);
